<script setup lang="ts">
import HeaderAll from "~/components/globals/Header/HeaderAll.vue";
import Footer from "~/components/globals/Footer/Footer.vue";
import SectionPartners from "~/components/sections/SectionPartners/SectionPartners.vue";

defineProps ({
  extraClass: {
    type: String,
    default: ''
  }
})

const apiLoaded = inject('pageLoaded', true)

</script>

<template>
  <div :class='extraClass'>
    <HeaderAll />
    <slot name="sticky_position"></slot>
    <main>
      <slot name="body_page"></slot>
    </main>
    <template v-if="apiLoaded">
      <SectionPartners/>
      <Footer />
    </template>
  </div>
</template>

<style scoped lang="scss">

</style>
